const getAssembleUrlWithParams = (itid) => (timestamp, url) => {
  const href = (url || window.location.href)
    .replace(/([?&]reload=true)/gi, "")
    .replace(/[?&]itid=force_refresh(_elections)?/gi, "")
    .replace(/[?&]_=\d+/gi, "");
  const joiner = href.includes("?") ? `&` : `?`;
  return `${href}${joiner}reload=true&itid=force_refresh${itid}&_=${timestamp}`;
};

export default getAssembleUrlWithParams;
